import { motion } from "framer-motion"
import { motionVariants } from "../Common/GlobalVars"
import { ArticleItemProps } from "../Mamapedia/constants";
import MinArticle from "../Mamapedia/MinArticle";
import HorizontalArticle from "../Mamapedia/HorizontalArticle";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import Link from 'next/link';

import "swiper/css";
import "swiper/css/pagination";


interface MamapediaListProps {
  articles: Array<ArticleItemProps>;
}

const MamapediaList = (props: MamapediaListProps) => {
  const { articles } = props;

  if (articles.length === 0) {
    return null;
  }

  return (
    <motion.div
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
      variants={motionVariants}
      className="container mb-12 md:mb-20 font-poppins"
    >
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-base font-bold text-black md:text-2xl">Mamapedia Articles</h3>
        <Link href="/mamapedia-homepage">
          <div className="text-xs font-normal text-black underline font-poppins">
            Lihat Semua
          </div>
        </Link>
      </div>
      <div className="hidden md:block">
        <Swiper
          spaceBetween={16}
          centeredSlides={false}
          loop={true}
          slidesPerView={3}
          slidesPerGroup={3}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          pagination={{
            clickable: true,
            enabled: true,
          }}
          modules={[Autoplay, Pagination]}
          className="review-swiper !pb-10"
        >
          {articles.map((article, index) => (
            <SwiperSlide key={index}>
              <MinArticle key={index} article={article} option={process.env.NEXT_PUBLIC_STYLE_OPTION || "B"} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="md:hidden">
        <Swiper
          spaceBetween={0}
          centeredSlides={false}
          loop={true}
          slidesPerView={1}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          pagination={{
            clickable: true,
            enabled: true,
          }}
          modules={[Autoplay, Pagination]}
          className="review-swiper !pb-10"
        >
          {articles.flatMap((article, index) => {
            if (index % 2 === 0 && articles[index + 1]) {
              return (
                <SwiperSlide key={index} className="space-y-4">
                  <HorizontalArticle article={article} animated={false} />
                  <HorizontalArticle article={articles[index + 1]} animated={false} />
                </SwiperSlide>
              )
            }
          })}
        </Swiper>
      </div>
    </motion.div>
  )
}

export default MamapediaList
