import Image from 'next/image';
import { motion } from "framer-motion";
import { PeopleSayItemProps } from "../Utils/constants";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { motionVariants } from '../Common/GlobalVars';
import Cookies from 'js-cookie';

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


interface ReviewsProps {
  reviews: Array<PeopleSayItemProps>;
  option?: string;
}

const Reviews = (props: ReviewsProps) => {
  const { reviews, option } = props;
  const viewport = Cookies.get('viewport');

  if (option === "A") {
    return (
      <motion.div
        className="mb-12 md:mb-20 font-poppins"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
        variants={motionVariants}
      >
        <div className="md:container md:block">
          <h3 className="pl-6 mb-6 text-base font-bold text-black md:text-2xl md:pl-0">Customer Review</h3>
          <Swiper
            spaceBetween={viewport === "desktop" ? 28 : 16}
            centeredSlides={false}
            loop={true}
            navigation={false}
            slidesPerView={viewport === "desktop" ? 4 : 1.6}
            slidesPerGroup={viewport === "desktop" ? 4 : 1}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            pagination={{
              clickable: true,
              enabled: true,
            }}
            modules={[Autoplay, Pagination]}
            className="review-swiper !pl-6 md:!pl-0"
          >
            {reviews.map((review, index) => {
              return (viewport === "desktop" || (viewport !== "desktop" && index < 5)) && (<SwiperSlide key={index} className="pb-10">
                <div className="flex flex-col">
                  <Image
                    src={review.image}
                    alt={review.name}
                    quality={100}
                    width={300}
                    height={300}
                    className="!object-cover rounded aspect-square"
                  />
                  <p className="mt-2 text-base font-bold">{review.artist_name}</p>
                  <p className="mt-2 text-xs text-brandBorder">{review.job_title}</p>
                  <p className="mt-2 text-sm font-medium text-black line-clamp-4 text-ellipsis">{review.excerpt}</p>
                </div>
              </SwiperSlide>)
          })}
          </Swiper>
        </div>
      </motion.div>
    )
  } else {
    return (
      <motion.div
        className="mb-12 md:mb-20 font-poppins"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
        variants={motionVariants}
      >
        <div className="container hidden md:block">
          <h3 className="mb-12 text-2xl font-bold text-black">Customer Review</h3>
          <Swiper
            spaceBetween={20}
            centeredSlides={false}
            loop={true}
            navigation={false}
            slidesPerView={4}
            slidesPerGroup={4}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            pagination={{
              clickable: true,
              enabled: true,
            }}
            modules={[Autoplay, Pagination]}
            className="review-swiper"
          >
            {reviews.map((review, index) => (
              <SwiperSlide key={index} className="pb-10">
                <div className="flex flex-col items-center">
                  <div className="relative review-item">
                    <Image
                      src={review.image}
                      alt={review.name}
                      quality={100}
                      width={300}
                      height={300}
                      className="brightness-[70%] !object-cover aspect-square"
                    />
                    <div className="text-white absolute left-6 bottom-[28px] z-20">
                      <p className="text-base font-semibold">{review.artist_name}</p>
                      <p className="mt-2 text-sm">{review.job_title}</p>
                    </div>
                    <div className="absolute top-0 left-0 w-full h-full text-white transition-transform duration-300 ease-in-out bg-review opacity-80">
                      <p className="absolute text-sm font-medium top-6 left-6 right-6">{review.excerpt}</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="container py-6 md:hidden bg-reviewBg">
          <h3 className="mb-6 text-base font-bold text-center text-black">Customer Review</h3>
          <Swiper
            spaceBetween={0}
            centeredSlides={true}
            loop={true}
            navigation={true}
            slidesPerView={1}
            slidesPerGroup={1}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            pagination={false}
            modules={[Autoplay, Navigation]}
            className="review-swiper"
          >
            {reviews.map((review, index) => {
              if (index < 8) {
                return (
                  <SwiperSlide key={index} className="pb-10">
                    <div className="w-full px-4">
                      <Image
                        src={review.image}
                        alt={review.name}
                        quality={100}
                        width={300}
                        height={300}
                        className="brightness-[70%] rounded"
                      />
                      <div className="text-black">
                        <p className="h-20 mt-4 text-xs font-medium line-clamp-5 text-ellipsis">{review.excerpt}</p>
                        <p className="mt-6 text-sm font-semibold">{review.artist_name}</p>
                        <p className="mt-2 text-xs">{review.job_title}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              }
            })}
          </Swiper>
        </div>
      </motion.div>
    )
  }
}

export default Reviews
