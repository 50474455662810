import Image from "next/image"
import AlertDialog from "../Utils/AlertDialog"
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { motionVariants } from "../Common/GlobalVars";


const benefitList = [
  {
    text_image: "express-delivery-text.png",
    image: "express-delivery.png",
    benefit: "express_delivery",
    title: "Ship hỏa tốc khu vực Hồ Chí Minh - Nhận hàng nhanh chóng",
    message: "Hỗ trợ phí ship lên đến 40.000 VND cho các đơn hàng trong khu vực Hồ Chí Minh. Để được giao hàng hỏa tốc, vui lòng liên hệ Facebook Mooimom Việt Nam ngay nhé!"
  },
  {
    text_image: "authentic-product-text.png",
    image: "authentic-product.png",
    benefit: "authentic_product",
    title: "Mua hàng chính hãng",
    message: "Đảm bảo mua hàng 100% chính hãng. Không lo mua phải hàng giả, hàng nhái. hàng kém chất lượng.<br />An tâm hơn khi mua sắm!"
  },
  {
    text_image: "free-returns-text.png",
    image: "free-returns.png",
    benefit: "free_returns",
    title: "Đổi trả miễn phí trong vòng 7 ngày",
    message: "Không ưng ý? Đổi trả dễ dàng! Trong vòng 7 ngày từ khi nhận hàng, bạn có thể đổi hoặc hoàn trả nếu sản phẩm không phù hợp hoặc có lỗi từ nhà sản xuất.<br />Lưu ý: Sản phẩm cần giữ nguyên trạng, chưa qua sử dụng."
  },
  {
    text_image: "cheaper-price-text.png",
    image: "cheaper-price.png",
    benefit: "cheaper_price",
    title: "Giá ưu đãi rẻ nhất các sàn",
    message: "Mua hàng trực tiếp trên website để nhận mức giá ưu đãi nhất, luôn rẻ hơn so với các sàn thương mại điện tử. Tiết kiệm hơn – Mua sắm dễ dàng hơn!"
  },
  {
    text_image: "free-gift-text.png",
    image: "free-gift.png",
    benefit: "free_gift",
    title: "Quà tặng miễn phí",
    message: "Ưu đãi quà tặng cho mỗi đơn hàng chỉ định - đừng bỏ lỡ ưu đãi hấp dẫn này!"
  }
]

const Benefit = () => {
  const [open, setOpen] = useState(false);
  const [tittle, setTittle] = useState("");
  const [message, setMessage] = useState("");

  const handleClick = (benefit: string) => {
    const benefitData = benefitList.find(item => item.benefit === benefit);

    if (benefitData) {
      setTittle(benefitData.title);
      setMessage(benefitData.message);
    }
  }

  useEffect(() => {
    if (message.length > 0) {
      setOpen(true);
    }
  }, [message])

  useEffect(() => {
    if (!open) {
      setTittle("");
      setMessage("");
    }
  }, [open])

  return (
    <motion.div
      className="my-16 md:container"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
      variants={motionVariants}
    >
      <div className="ml-6 text-base font-bold text-black md:text-2xl font-poppins md:ml-0">Benefit Shop in Website</div>
      <div className="flex px-6 pt-6 pb-4 space-x-4 overflow-x-auto flex-nowrap md:hidden scrollbar-hide">
        <div className="space-y-4">
          {benefitList.slice(0, 2).map((item, index) => (
            <div
              key={index}
              className="relative min-w-[300px] h-40 rounded shadow-[0_2px_12px_2px_rgb(0,0,0,0.1)] cursor-pointer"
              onClick={() => handleClick(item.benefit)}
            >
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + `/images/benefit/${item.text_image}`}
                alt={item.title + " text"}
                width={200}
                height={100}
                className="absolute top-0 left-0"
              />
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + `/images/benefit/${item.image}`}
                alt={item.title}
                width={140}
                height={140}
                className="absolute bottom-0 right-0"
              />
            </div>
          ))}
        </div>
        <div className="space-y-4">
          {benefitList.slice(2, 3).map((item, index) => (
            <div
              key={index}
              className="relative min-w-[300px] rounded shadow-[0_2px_12px_2px_rgb(0,0,0,0.1)] h-[336px] cursor-pointer"
              onClick={() => handleClick(item.benefit)}
            >
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + `/images/benefit/${item.text_image}`}
                alt={item.title + " text"}
                width={300}
                height={150}
                className="absolute top-0 left-0"
              />
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + `/images/benefit/${item.image}`}
                alt={item.title}
                width={200}
                height={200}
                className="absolute bottom-0 right-0"
              />
            </div>
          ))}
        </div>
        <div className="space-y-4">
          {benefitList.slice(3, 5).map((item, index) => (
            <div
              key={index}
              className="relative min-w-[300px] h-40 rounded shadow-[0_2px_12px_2px_rgb(0,0,0,0.1)] cursor-pointer"
              onClick={() => handleClick(item.benefit)}
            >
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + `/images/benefit/${item.text_image}`}
                alt={item.title + " text"}
                width={200}
                height={100}
                className="absolute top-0 left-0"
              />
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + `/images/benefit/${item.image}`}
                alt={item.title}
                width={140}
                height={140}
                className="absolute bottom-0 right-0"
              />
            </div>
          ))}
        </div>
      </div>
      <div className="hidden grid-cols-3 gap-6 pt-6 pb-4 md:grid md:grid-rows-2 md:grid-flow-col">
        {benefitList.map((item, index) => (
          <div
            key={index}
            className={`relative w-full ${index !== 2 ? "h-40" : "h-84 row-span-2"} rounded shadow-[0_2px_12px_2px_rgb(0,0,0,0.1)] cursor-pointer`}
            onClick={() => handleClick(item.benefit)}
          >
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + `/images/benefit/${item.text_image}`}
              alt={item.title + " text"}
              width={index !== 2 ? 200 : 300}
              height={index !== 2 ? 100 : 150}
              className="absolute top-0 left-0"
            />
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + `/images/benefit/${item.image}`}
              alt={item.title}
              width={index !== 2 ? 140 : 200}
              height={index !== 2 ? 140 : 200}
              className="absolute bottom-0 right-0"
            />
          </div>
        ))}
      </div>
      <AlertDialog open={open} setOpen={setOpen} message={message} tittle={tittle} />
    </motion.div>
  )
}

export default Benefit
