import { motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { motionVariants } from '../Common/GlobalVars';
import Cookies from 'js-cookie';
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";


const categories = [
  {
    title: "Tất cả sản phẩm",
    image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/all-product.png",
    link: "/product-category/recommended-products-custom-all-products",
  },
  {
    title: "Máy hút sữa",
    image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/breast-pump.png",
    link: "/product-category/recommended-products-custom-breast-pump",
  },
  {
    title: "Đai bụng",
    image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/corset.png",
    link: "/product-category/recommended-products-custom-corset",
  },
  {
    title: "Địu trợ lực",
    image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/hipseat.png",
    link: "/product-category/recommended-products-custom-hipseat",
  },
  {
    title: "Áo lót",
    image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/bra.png",
    link: "/product-category/recommended-products-custom-bra",
  },
  {
    title: "Túi xách",
    image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/bag.png",
    link: "/product-category/recommended-products-custom-bag",
  },
  {
    title: "Đồ ăn dặm",
    image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/tableware.png",
    link: "/product-category/recommended-products-custom-tableware",
  },
  {
    title: "Đồ chơi",
    image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/toys.png",
    link: "/product-category/recommended-products-custom-toys",
  }
]

const ShopByCategory = () => {
  const viewport = Cookies.get('viewport');

  return (
    <motion.div
      className="my-12 md:container font-poppins"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
      variants={motionVariants}
    >
      <h3 className="px-6 mb-6 text-base font-bold text-black md:text-2xl md:mb-12 md:px-0">Shop by Category</h3>
      <Swiper
        spaceBetween={viewport === "desktop" ? 24 : 20}
        centeredSlides={false}
        loop={false}
        slidesPerView={viewport === "desktop" ? 6.4 : 3.4}
        className="!pl-6 md:!pl-0"
      >
        {categories.map((category, index) => (
          <SwiperSlide key={index} className="w-20 min-w-20 md:w-[130px] md:min-w-[130px]">
            <Link href={category.link}>
              <div className="rounded-full shadow-md bg-gradient-to-r from-shopByCategoryStartBg to-shopByCategoryEndBg">
                <Image
                  src={category.image}
                  alt={category.title}
                  quality={100}
                  width={400}
                  height={400}
                  className="rounded-full"
                />
              </div>
              <div className="mx-2 mt-6 text-xs font-medium text-center text-black md:text-sm">{category.title}</div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </motion.div>
  )
}

export default ShopByCategory
